.main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 80%;
}

body {
  background-color: #d0e6e3;
  font-family: Heebo;
  font-weight: 900;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #dbe7db;
  color: #5d3c59;
  text-align: center;
  font-size: 20px;
}

.header {
  position: fixed;
  top: 10px;
  right: 10px;
  color: #5d3c59;
  text-align: center;
  font-size: 20px;
}

.gradeToggle {
  vertical-align: middle;
}
